.nopriv-barbed-wire {
    width: 100%;
    height: auto;
    pointer-events: none;
    user-select: none;

    img {
        width: 100%;
        height: auto;
    }
}