img {
    width: auto;
    height: auto;
}

.card {
    background-color: $color-gray;
    box-shadow: $box-shadow;
    @include background-fill;
}

.page-backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -10;

    &__img {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__video {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &--faded {
        .page-backdrop__img {
            opacity: .9;

            img {
                width: 105%;
                height: 105%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                filter: blur(4px);
            }
        }

        .page-backdrop__video {
            // opacity: .8;
            &:after {
                content: '';
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, transparentize($color-red, .4), transparentize($color-text, .3));
                position: absolute;
            }
        }
    }
}

.error-notice {
    padding: 1rem;
    background-color: $color-error;
    color: $color-error-text;
    line-height: $line-height;

    &+.error-notice {
        margin-top: .5rem;
    }
}
.success-notice {
    padding: 1rem;
    background-color: $color-success;
    color: $color-success-text;
    line-height: $line-height;

    &+.success-notice {
        margin-top: .5rem;
    }
}