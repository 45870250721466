.text-white {
    color: #fff;
}
.text-dark {
    color: $color-text;
}

.text-bold {
    font-weight: bold;
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: right;
}
.text-right {
    text-align: right;
}