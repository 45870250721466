.header-nopriv {
    background-color: $color-gray;
    box-shadow: $box-shadow;
    @include background-fill;

    &__logo {
        width: 160px;
        text-decoration: none;
        display: block;
        user-select: none;

        img {
            max-width: 100%;
        }
    }
}