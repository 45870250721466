$color-red: #771028;
$color-gray: rgb(238, 238, 238);
$color-dark: #242424;
$color-text: $color-dark;

$color-error: #ddbec5;
$color-error-text: $color-red;
$color-success: #c6ddbe;
$color-success-text: #346523;

$box-shadow: 0 0 10px 0 transparentize($color-text, .7);

$container-max-widths: (
  sm: 540px,
  md: 920px,
  lg: 1210px,
  xl: 1450px
);

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 922px;
$breakpoint-xl: 1200px;
$breakpoint-max: 1450px;

$line-height: 1.8;
$font-path: './fonts/';
// TEXT
$font-name: 'RobotoSlab';
$font-stack: '#{$font-name}', Helvetica, Arial, sans-serif;

// TITLES
$font-name-title: 'RobotoSlab';
$font-stack-title: '#{$font-name-title}', Helvetica, Arial, sans-serif;

// MONOSPACE
$font-name-monospace: 'Krungthep';
$font-stack-monospace: '#{$font-name-monospace}', Helvetica, Arial, sans-serif; 

$load-font-weight_1: '-Regular';
@font-face {
  font-family: '#{$font-name}';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('#{$font-path}#{$font-name}#{$load-font-weight_1}.eot'); /* IE11 */
  src: url('#{$font-path}#{$font-name}#{$load-font-weight_1}.eot?#iefix') format('embedded-opentype'),
          url('#{$font-path}#{$font-name}#{$load-font-weight_1}.woff2') format('woff2'),
          url('#{$font-path}#{$font-name}#{$load-font-weight_1}.woff') format('woff'),
          url('#{$font-path}#{$font-name}#{$load-font-weight_1}.ttf') format('truetype');
}

$load-font-weight_2: '-Bold';
@font-face {
  font-family: '#{$font-name-title}';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('#{$font-path}#{$font-name-title}#{$load-font-weight_2}.eot'); /* IE11 */
  src: url('#{$font-path}#{$font-name-title}#{$load-font-weight_2}.eot?#iefix') format('embedded-opentype'),
          url('#{$font-path}#{$font-name-title}#{$load-font-weight_2}.woff2') format('woff2'),
          url('#{$font-path}#{$font-name-title}#{$load-font-weight_2}.woff') format('woff'),
          url('#{$font-path}#{$font-name-title}#{$load-font-weight_2}.ttf') format('truetype');
}

$load-font-weight_3: '-Regular';
@font-face {
  font-family: '#{$font-name-monospace}';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('#{$font-path}#{$font-name-monospace}#{$load-font-weight_3}.eot'); /* IE11 */
  src: url('#{$font-path}#{$font-name-monospace}#{$load-font-weight_3}.eot?#iefix') format('embedded-opentype'),
          url('#{$font-path}#{$font-name-monospace}#{$load-font-weight_3}.woff2') format('woff2'),
          url('#{$font-path}#{$font-name-monospace}#{$load-font-weight_3}.woff') format('woff'),
          url('#{$font-path}#{$font-name-monospace}#{$load-font-weight_3}.ttf') format('truetype');
}

// GAME
$sidebar-width: 300px; 
$hud-max-width: 600px; 
$gap-size: 2rem;
$border-radius: .3rem;
$text-shadow: 0 0 3px transparentize($color-text, .5), 0 0 5px transparentize($color-text, .5);
$text-shadow-white: 0 0 8px transparentize(#fff, .7), 0 0 11px transparentize(#fff, .7);