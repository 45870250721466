$cpx-button-py: 11px;
$cpx-button-px: 30px;
$cpx-button-b-radius: 0px;

.cpx-button {
    padding: #{$cpx-button-py} #{$cpx-button-px};
    padding-bottom: #{$cpx-button-py - 2px};
    cursor: pointer;
    font-size: 1.1em;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    line-height: 1.5em;
    transition: opacity 0.15s ease-out;
    appearance: none;
    font-family: $font-stack-title;
    border-radius: 0;
    text-decoration: none;
    font-weight: 400;
    vertical-align: middle;
    border: none;
    -webkit-tap-highlight-color: transparent;
    font-weight: bold;

    background-color: $color-red;
    background-size: 500%;
    background-repeat: no-repeat;
    color: #fff;

    &:hover {
        background-color: lighten($color-red, 5%);
        color: #fff !important;
        opacity: .9;
    } 

    &:focus {
        box-shadow: 0 0 0px 1px transparentize($color-red, 0);
    }

    &--white {
        background-color: #fff;
        color: $color-red;
        border-color: #fff;

        &:hover {
            color: $color-red;
            background-color: darken(#fff, 10%);
        }
    }
}

.cpx-button-group {
    > .cpx-button {
        margin-bottom: .5em;
    }
    > .cpx-button + .cpx-button {
        margin-left: 1.5em;
    }

    @media screen and (max-width: 450px) {
        display: flex;
        flex-direction: column;

        > .cpx-button + .cpx-button {
            margin-left: 0;
        }
    }
}