* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body {
    font-family: $font-stack;
    color: $color-text;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $font-stack-title;
	font-weight: bold;
    margin-top: 0;
	margin-bottom: .25em; 
}

h1, .h1 {
	font-size: 30px;

	@media screen and (min-width: 350px) {
		font-size: 35px;
	}
	@media screen and (min-width: $breakpoint-lg) {
		font-size: 50px;
	}
}

h2, .h2 {
	font-size: 25px;

	@media screen and (min-width: 350px) {
		font-size: 35px;
	}

	@media screen and (min-width: $breakpoint-lg) {
		font-size: 45px;
	}
}

h3, .h3 {
	font-size: 21px;

	@media screen and (min-width: 350px) {
		font-size: 32px;
	}

	@media screen and (min-width: $breakpoint-lg) {
		font-size: 35px;
	}
}

h4, .h4 {
	font-size: 18px;

	@media screen and (min-width: 350px) {
		font-size: 23px;
	}

	@media screen and (min-width: $breakpoint-lg) {
		font-size: 28px;
	}
}


p, ul, ol {
	font-size: 1rem;
	
	@media screen and (min-width: $breakpoint-md) {
		font-size: 1.05rem;
	}

	a {
		color: inherit;
	}
}

p, ul, ol {
    line-height: $line-height;
	font-size: 1rem;
	
	@media screen and (min-width: $breakpoint-md) {
		font-size: 1.05rem;
	}

	a {
		color: inherit;
	}
}

::-moz-selection { background: transparentize($color-red, 0); color: white; }
::selection { background: transparentize($color-red, 0); color: white; }